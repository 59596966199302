// src/components/SimpleFooter.js
import React from 'react';

const SimpleFooter = () => {
  return (
    <footer className="bg-deepblue border-t border-darkgray">
      <div className="w-full px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex flex-col md:flex-row md:justify-between md:items-center space-y-4 md:space-y-0">
          <div className="text-sm text-lightgray">
            © 2025 AI Guru. All rights reserved.
          </div>
          <div className="flex space-x-6">
            <a
              href="/termsofservice"
              className="text-salmon hover:text-lightblue transition-colors duration-200"
            >
              Terms of Service
            </a>
            <a
              href="/privacy-policy"
              className="text-salmon hover:text-lightblue transition-colors duration-200"
            >
              Privacy Policy
            </a>
            <a
              href="/contactUs"
              className="text-salmon hover:text-lightblue transition-colors duration-200"
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default SimpleFooter;
