import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  InputBase,
  Box,
  Menu,
  MenuItem,
  useScrollTrigger,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../images/logo.png';
import useApi from '../hooks/useApi';

const StyledAppBar = styled(AppBar)(({ theme, trigger }) => ({
  backgroundColor: trigger
    ? alpha(theme.palette.background.paper, 0.8)
    : alpha(theme.palette.background.default, 0.8),
  boxShadow: trigger ? theme.shadows[4] : 'none',
  transition: theme.transitions.create(['background-color', 'box-shadow', 'color'], {
    duration: theme.transitions.duration.short,
  }),
  color: trigger ? theme.palette.text.primary : theme.palette.text.secondary,
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const Header = () => {
  const navigate = useNavigate();
  const { loginWithRedirect, logout, isAuthenticated, user } = useAuth0();
  const [searchQuery, setSearchQuery] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { fetchCourses } = useApi();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleSearch = async (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      try {
        const response = await fetchCourses({ search: searchQuery });
        navigate('/courses', { state: { searchResults: response.courses, searchQuery } });
      } catch (error) {
        console.error('Error searching courses:', error);
        navigate(`/courses?search=${encodeURIComponent(searchQuery)}`);
        setSearchQuery('');
      }
    } else {
      navigate('/courses');
    }
  };

  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleUserMenu = (event) => setUserMenuAnchorEl(event.currentTarget);
  const handleCloseMenus = () => {
    setAnchorEl(null);
    setUserMenuAnchorEl(null);
  };

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  const handleLogin = () => {
    loginWithRedirect();
  };

  const buttonStyles = (isActive) => ({
    backgroundColor: isActive ? alpha(theme.palette.primary.light, 0.2) : 'transparent',
    fontWeight: isActive ? 'bold' : 'normal',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.light, 0.3),
    },
  });

  return (
    <StyledAppBar position="fixed" trigger={trigger}>
      <Toolbar>
        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 0, display: 'flex', alignItems: 'center' }}
        >
          <Link to="/" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}>
            <Box
              component="img"
              src={logo}
              alt="AI Guru Logo"
              sx={{
                width: 32,
                height: 32,
                marginRight: 1,
              }}
            />
            AI Guru
          </Link>
        </Typography>

        {!isMobile && (
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <form onSubmit={handleSearch}>
              <StyledInputBase
                placeholder="Search courses..."
                inputProps={{ 'aria-label': 'search' }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </form>
          </Search>
        )}

        <Box sx={{ flexGrow: 1 }} />

        {isMobile ? (
          <>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              keepMounted
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={Boolean(anchorEl)}
              onClose={handleCloseMenus}
            >
              <MenuItem component={Link} to="/" onClick={handleCloseMenus}>
                Home
              </MenuItem>
              <MenuItem component={Link} to="/courses" onClick={handleCloseMenus}>
                Courses
              </MenuItem>
              {isAuthenticated ? (
                <>
                  <MenuItem component={Link} to="/dashboard" onClick={handleCloseMenus}>
                    Dashboard
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </>
              ) : (
                <MenuItem onClick={handleLogin}>Login</MenuItem>
              )}
            </Menu>
          </>
        ) : (
          <>
            <Button
              color="inherit"
              component={Link}
              to="/"
              sx={buttonStyles(window.location.pathname === '/')}
            >
              Home
            </Button>
            <Button
              color="inherit"
              component={Link}
              to="/courses"
              sx={buttonStyles(window.location.pathname === '/courses')}
            >
              Courses
            </Button>
            {isAuthenticated ? (
              <>
                <Button
                  color="inherit"
                  component={Link}
                  to="/dashboard"
                  sx={buttonStyles(window.location.pathname === '/dashboard')}
                >
                  Dashboard
                </Button>
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls="user-menu"
                  aria-haspopup="true"
                  onClick={handleUserMenu}
                  color="inherit"
                >
                  <Box
                    component="img"
                    src={user.picture}
                    alt="Profile"
                    sx={{ width: 32, height: 32, borderRadius: '50%' }}
                  />
                </IconButton>
                <Menu
                  id="user-menu"
                  anchorEl={userMenuAnchorEl}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  keepMounted
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  open={Boolean(userMenuAnchorEl)}
                  onClose={handleCloseMenus}
                >
                  <MenuItem component={Link} to="/profile" onClick={handleCloseMenus}>
                    Profile
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </>
            ) : (
              <>
                <Button color="inherit" onClick={handleLogin}>
                  Login
                </Button>
                <Button
                  color="inherit"
                  onClick={() => loginWithRedirect({ screen_hint: 'signup' })}
                  sx={{
                    background: 'linear-gradient(to right, #4A90E2, #E2A08C)',
                    color: 'white',
                    fontWeight: 'bold',
                    '&:hover': { opacity: 0.9 },
                  }}
                >
                  Sign Up
                </Button>
              </>
            )}
          </>
        )}
      </Toolbar>
    </StyledAppBar>
  );
};

export default Header;
