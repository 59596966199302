import React from "react";

const CustomAIPage = () => {
    return (
        <div className="font-sans my-2">
            {/* First Section */}
            <div className="text-gray-900 px-4 md:px-12 flex-row md:flex md:justify-between my-4">
                <div className="text-start ml-4 my-4">
                    <h1 className="text-6xl lg:text-8xl mb-4">
                        Tailor AI to Fit <br /> Your Business Needs
                    </h1>
                    <p className="text-lg lg:text-xl mb-8">
                        From fraud detection to predictive analytics, our custom AI models
                        are designed to solve your unique challenges.
                    </p>
                    <button className="bg-gray-800 text-white py-6 px-12 rounded-lg hover:bg-gray-700">
                        Start Custom AI
                    </button>
                </div>
                <div className="m-2">
                    <img
                        src="https://images.squarespace-cdn.com/content/v1/671f15a34acff55ddc02afbc/4923b7a1-b3dc-4337-9c3d-5db5339ddc74/custom1.jpeg?format=500w" // Replace with your image URL
                        alt="Custom AI Models"
                        className="h-96 lg:h-full max-w-lg"
                    />
                </div>
            </div>

            {/* Why Custom AI Models Section */}
            <section className="bg-gray-700 text-pink-50 px-4 md:px-12 py-4 flex flex-col md:flex-row justify-between">
                <div className="flex justify-start pl-4 mb-8 md:mb-0">
                    <img
                        src="https://img.freepik.com/premium-photo/abstract-3d-shape-light-iridescent-background_272415-512.jpg" // Replace with your image URL
                        alt="Why Custom AI Models"
                        className="h-96 md:h-[496px] max-w-lg"
                    />
                </div>
                <div className="text-start flex h-auto ml-4 items-center max-w-lg md:pr-4">
                    <div>
                        <h2 className="text-4xl md:text-5xl mb-16 mr-8 ">
                            Why Custom AI Models?
                        </h2>
                        <p className="text-2xl lg:text-3xl leading-10">
                            Generic models don't always meet industry-specific needs. Custom
                            AI solutions ensure accuracy, efficiency, and relevance by
                            aligning AI to your data and workflows.
                        </p>
                    </div>
                </div>
            </section>

            {/* Real-World Applications Section */}
            <section className="bg-pink-500 text-gray-900 px-6 py-16">
                <div className="max-w-7xl mx-auto text-center">
                    <h2 className="text-4xl font-bold mb-12">
                        Real-World Applications of Custom AI
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
                        <div className="bg-white flex flex-col items-center p-8 shadow-lg">
                            <h3 className="text-xl font-semibold mb-4">Fraud Detection</h3>
                            <p className="text-gray-700 text-sm mb-4">
                                <strong>Real-time anomaly detection for banking and finance.</strong>
                                <br />
                                • Custom LLMs analyze transaction patterns, flagging suspicious
                                activities.
                                <br />
                                • Fine-tuned AI models detect subtle fraud attempts that general
                                models might miss.
                            </p>
                            <img
                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq1A7OcxPEyeh_Ccc9xacCT1vbP3Ir8Dqv24fk0OropvalelRitn6FTryuwnhcyE80ioQ&usqp=CAU"
                                alt="Fraud Detection"
                                className="w-24 h-24 rounded-full mb-4 mt-6"
                            />
                        </div>
                        <div className="bg-white flex flex-col items-center p-8 shadow-lg">
                            <h3 className="text-xl font-semibold mb-4">Predictive Analytics</h3>
                            <p className="text-gray-700 text-sm mb-4">
          <strong>
            Forecasting trends and patterns in retail and healthcare.
          </strong>
          <br />
          • Train LLMs to predict demand surges, customer behavior, or potential
          health risks.
          <br />
          • Industry-specific datasets ensure accuracy beyond off-the-shelf
          models.
        </p>
                            <img
                                src="https://img.freepik.com/premium-photo/visual-representation-social-media-marketing-metrics_1104763-15006.jpg"
                                alt="Predictive Analytics"
                                className="w-24 h-24 rounded-full mb-4 mt-6"
                            />
                        </div>
                        <div className="bg-white flex flex-col items-center p-8 shadow-lg">
                            <h3 className="text-xl font-semibold mb-4">
                                Retrieval-Augmented Generation (RAG)
                            </h3>
                            <p className="text-gray-700 text-sm mb-4">
          <strong>
            AI agents engaging potential clients automatically.
          </strong>
          <br />
          • Build AI that pulls insights from proprietary databases, manuals,
          and reports to provide accurate, real-time answers.
          <br />
          • Applied in legal, healthcare, or financial services for robust
          document search, chatbot assistance, and policy Q&A.
        </p>
                            <img
                                src="https://i1.sndcdn.com/artworks-TlJZHz7KOXO2FDoE-Mto5eA-t500x500.jpg"
                                alt="RAG"
                                className="w-24 h-24 rounded-full mb-4"
                            />
                        </div>
                        <div className="bg-white flex flex-col items-center p-8 shadow-lg">
                            <h3 className="text-xl font-semibold mb-4">Process Optimization</h3>
                            <p className="text-gray-700 text-sm mb-4">
          <strong>
            AI-driven insights to enhance manufacturing and supply chain
            operations.
          </strong>
          <br />
          • Fine-tuned models monitor supply chain efficiency, detect
          bottlenecks, and recommend process improvements.
          <br />
          • AI predicts machine maintenance needs, reducing downtime and
          increasing productivity.
        </p>
                            <img
                                src="https://res.cloudinary.com/canvyapp/image/upload/c_limit,f_auto,q_auto:eco,dpr_auto/v1/pro/thumbs/e4275abadb264a848475eee22e42a7b2.jpg"
                                alt="Process Optimization"
                                className="w-24 h-24 rounded-full  mb-4 mt-6"
                            />
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
};

export default CustomAIPage;
