import React from "react";

const VirtualAgentsPage = () => {
  return (
    <div className="font-sans my-2">
      {/* First Section */}
      <div className="bg-inherit text-gray-900 px-4 md:px-12 flex-row md:flex md:justify-between my-4">
        <div className="text-start ml-4 my-4">
          <h1 className="text-6xl lg:text-8xl max-w-2xl mb-4">
            Build <br/> Intelligent Virtual Agents  for Your Business
          </h1>
          <p className="text-lg lg:text-xl mb-8">
            Automate workflows, enhance customer interactions, and drive
            efficiency with AI-powered agents.
          </p>
          <button className="bg-gray-800 text-white py-6 px-12 rounded-lg hover:bg-gray-700">
            Discover AI Agents
          </button>
        </div>
        <div className="m-2">
          <img
            src="https://images.squarespace-cdn.com/content/v1/671f15a34acff55ddc02afbc/e49aa85e-0eef-4176-91db-4c27ee6cfcd4/agent.jpg?format=500w" // Replace with your image URL
            alt="AI Agents Visualization"
            className="h-96 lg:h-full max-w-lg"
          />
        </div>
      </div>

     
      <section className="bg-gray-700 text-pink-50 px-4 md:px-12 py-4 flex flex-col md:flex-row justify-between">
        <div className="flex justify-start pl-4 mb-8 md:mb-0">
          <img
            src="https://cdn.prod.website-files.com/659df5fe05c8b843804f1da7/65cd4cd1425d7f6d1ce5f00f_Screenshot%202024-02-14%20at%206.29.04%20PM%20(1).png" // Replace with your image URL
            alt="AI Agent Illustration"
            className="h-96 md:h-[496px] max-w-lg"
          />
        </div>
        <div className="text-start flex h-auto ml-4 items-center max-w-xl md:pr-4">
          <div>
            <h2 className="text-5xl md:text-6xl mb-16 ">What are AI Agents</h2>
            <p className="text-2xl lg:text-3xl leading-8">
              AI agents, or intelligent virtual assistants, automate repetitive
              tasks, engage customers, and drive workflows across industries.
              These agents can handle complex operations, reducing overhead and
              improving user experiences.
            </p>
          </div>
        </div>
      </section>

      {/* How AI Agents Enhance Business Operations */}
      <section className=" text-gray-900 px-6 py-16">
        <div className="max-w-7xl mx-auto text-center">
          <h2 className="text-6xl p-8 mb-12">
            How AI Agents Enhance Business Operations
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white flex flex-col items-center md:px-10  lg:px-20 pt-4 shadow-lg">
              <h3 className="text-xl font-semibold mb-4">
                Customer Service Automation
              </h3>
              <p className="text-gray-700 text-sm mb-4">
                Enhance customer support with AI chatbots and virtual agents.
              </p>
              <img
                src="https://casknx.com/wp-content/uploads/2023/10/patrick-tomasso-fMntI8HAAB8-crop.webp" // Replace with your image URL
                alt="Customer Service"
                className="w-24 h-24 rounded-full mb-4"
              />
            </div>
            <div className="bg-white flex flex-col items-center md:px-10  lg:px-20 pt-4 shadow-lg">
              <h3 className="text-xl font-semibold mb-4">Workflow Automation</h3>
              <p className="text-gray-700 text-sm mb-4">
                Streamline business processes and eliminate manual tasks.
              </p>
              <img
                src="https://go.isostech.com/hubfs/AI.png" // Replace with your image URL
                alt="Workflow Automation"
                className="w-24 h-24 rounded-full mb-4"
              />
            </div>
            <div className="bg-white flex flex-col items-center md:px-10  lg:px-20 pt-4 shadow-lg">
              <h3 className="text-xl font-semibold mb-4">Lead Generation</h3>
              <p className="text-gray-700 text-sm mb-4">
                Generate and qualify leads using advanced AI-driven tools.
              </p>
              <img
                src="https://sp-ao.shortpixel.ai/client/to_webp,q_glossy,ret_img,w_180,h_180/https://optibpo.com/wp-content/uploads/2020/05/How-to-get-the-most-out-of-a-Digital-Marketing-Specialist-offshore-optiBPO-180x180.png" // Replace with your image URL
                alt="Lead Generation"
                className="w-24 h-24 rounded-full mb-4"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default VirtualAgentsPage;
