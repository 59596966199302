import React, { useState } from "react";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";

const SolutionPage = () => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  
  const showQuote = location.pathname === "/solution";

  return (
    <div className="p-4 mt-14">
      <div className="md:hidden flex justify-between items-center">
        <h1 className="text-xl font-bold">AI Solutions</h1>
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="p-2 text-blue-600"
        >
          {isMenuOpen ? <FaTimes size="1.5em" /> : <FaBars size="1.5em" />}
        </button>
      </div>

      {/* Mobile Navigation Menu */}
      {isMenuOpen && (
        <div className="z-50">
          <nav className="w-1/2 flex flex-col ml-auto   p-4 rounded  mb-4 items-end">
            <NavLink
              to="gen-ai-solutions"
              className={({ isActive }) =>
                `px-3 py-2 rounded text-sm font-medium underline ${
                  isActive ? " text-red-600" : "text-blue-600 "
                }`
              }
              
              onClick={() => setIsMenuOpen(false)} // Close menu on click
            >
              Generative AI
            </NavLink>
            <NavLink
              to="ai-agents-solutions"
              className={({ isActive }) =>
                `px-3 py-2 rounded text-sm font-medium underline ${
                  isActive ? "text-red-600" : "text-blue-600"
                }`
              }
              onClick={() => setIsMenuOpen(false)} // Close menu on click
            >
              AI Agents
            </NavLink>
            <NavLink
              to="custom-ai-solutions"
              className={({ isActive }) =>
                `px-3 py-2 rounded text-sm font-medium underline ${
                  isActive ? "text-red-600" : "text-blue-600"
                }`
              }
              onClick={() => setIsMenuOpen(false)} // Close menu on click
            >
              Custom AI
            </NavLink>
          </nav>
        </div>
      )}

      {/* Desktop Navigation */}
      <div className="hidden md:block">
        <header className="mb-4 flex justify-between">
          <h1 className="text-2xl font-bold">AI Solutions</h1>
          <nav className="flex space-x-4">
            <NavLink
              to="gen-ai-solutions"
              className={({ isActive }) =>
                `px-3 py-2 rounded text-sm font-medium ${
                  isActive ? "text-white bg-blue-600" : "text-blue-600"
                }`
              }
            >
              Generative AI
            </NavLink>
            <NavLink
              to="ai-agents-solutions"
              className={({ isActive }) =>
                `px-3 py-2 rounded text-sm font-medium ${
                  isActive ? "text-white bg-blue-600" : "text-blue-600"
                }`
              }
            >
              AI Agents
            </NavLink>
            <NavLink
              to="custom-ai-solutions"
              className={({ isActive }) =>
                `px-3 py-2 rounded text-sm font-medium ${
                  isActive ? "text-white bg-blue-600" : "text-blue-600"
                }`
              }
            >
              Custom AI
            </NavLink>
          </nav>
        </header>
      </div>

      {/* Motivational Quote */}
      {showQuote && (
        <div className="mt-4 bg-yellow-100 p-4 rounded shadow">
          <p className="text-lg italic text-gray-700">
            "The best way to predict the future is to create it." – Abraham
            Lincoln
          </p>
        </div>
      )}

      {/* Render child routes */}
      <Outlet />
    </div>
  );
};

export default SolutionPage;
