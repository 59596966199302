// src/pages/CorporateTrainingPage.js
import React from 'react';
import { Link } from 'react-router-dom';
import SimpleFooter from '../components/SimpleFooter';

const CorporateTrainingPage = () => {
  return (
    <div className="flex flex-col min-h-screen bg-lightgray">
      {/* HERO SECTION WITH FULL-WIDTH BACKGROUND IMAGE */}
      <header
        className="relative text-white pt-24 px-4 pb-12 bg-no-repeat bg-cover bg-top min-h-[600px]"
        style={{
          backgroundImage: 'url("https://aigurupublic.s3.us-east-1.amazonaws.com/goaiguru/goAIguru-corp-training-hero.png")',
        }}
      >
        {/* no text or overlay here */}
      </header>

      {/* HERO TEXT + CTA (in its own section, below the hero) */}
      <section className="bg-white text-center py-8 px-4">
        <h2 className="text-3xl md:text-5xl font-bold mb-4">
	  {/*          Transform Your Workforce with Silicon Valley AI Expertise */}
        </h2>
        <p className="text-lg md:text-2xl max-w-3xl mx-auto mb-6 text-gray-700">
	  {/*      Empower your teams with cutting-edge AI knowledge from industry veterans
          who’ve built solutions at AWS, Cerebras, and Bloomberg. */}
        </p>
        <Link
          to="/contactUs"
          className="inline-block bg-gradient-to-r from-lightblue to-salmon text-white font-bold py-3 px-6 rounded-lg hover:opacity-90 transition duration-300"
        >
          Request a Corporate Demo
        </Link>
      </section>
      {/* WHY LEADING ORGANIZATIONS CHOOSE AI GURU */}
      <section className="bg-white py-12 px-4">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-3xl md:text-4xl font-bold text-deepblue text-center mb-12">
            Why Leading Organizations Choose AI Guru
          </h2>

          {/* 1st Row (Left Text, Right Image) */}
          <div className="flex flex-col md:flex-row items-center mb-12">
            {/* TEXT */}
            <div className="md:w-1/2 md:pr-8">
              <h3 className="text-2xl font-semibold text-deepblue mb-4">Customized Learning</h3>
              <p className="text-gray-700 text-base">
                Training programs tailored to your industry and team’s needs, with flexible delivery options.
                <br />
                <em className="text-sm">
                  Giving custom Generative AI Training to UBS North America Executives
                </em>
              </p>
            </div>
            {/* IMAGE */}
            <div className="md:w-1/2 mt-6 md:mt-0">
              {/* Force a 16:9 ratio with aspect-ratio plugin */}
              <div className="aspect-w-16 aspect-h-9">
                <img
                  src="https://aigurupublic.s3.us-east-1.amazonaws.com/goaiguru/ubs-corporate.JPG"
                  alt="Customized Learning"
                  className="object-cover rounded-md shadow-md"
                />
              </div>
            </div>
          </div>

          {/* 2nd Row (Left Image, Right Text) */}
          <div className="flex flex-col md:flex-row items-center mb-12">
            <div className="md:w-1/2 mb-6 md:mb-0">
              <div className="aspect-w-16 aspect-h-9">
                <img
                  src="https://aigurupublic.s3.us-east-1.amazonaws.com/goaiguru/Singapore-pubsec.JPG"
                  alt="Silicon Valley Expertise"
                  className="object-cover rounded-md shadow-md"
                />
              </div>
            </div>
            <div className="md:w-1/2 md:pl-8">
              <h3 className="text-2xl font-semibold text-deepblue mb-4">Silicon Valley Expertise</h3>
              <p className="text-gray-700 text-base">
                Learn from practitioners who’ve built and scaled AI solutions at tech giants. 
                Get insights from real-world implementations at AWS, Cerebras, and Bloomberg.
                <br />
                <em className="text-sm">
                  Teaching Singapore Public Sector team about power of Generative AI 👉
                </em>
              </p>
            </div>
          </div>

          {/* 3rd Row (Left Text, Right Image) */}
          <div className="flex flex-col md:flex-row items-center mb-12">
            <div className="md:w-1/2 md:pr-8">
              <h3 className="text-2xl font-semibold text-deepblue mb-4">Practical Implementation</h3>
              <p className="text-gray-700 text-base">
                92% success rate in implementing AI solutions post-training. 
                Our programs combine theoretical foundations with hands-on projects from real enterprise use cases.
                <br />
                <em className="text-sm">
                  Management &amp; Leadership at Mexico’s largest conglomerate
                </em>
              </p>
            </div>
            <div className="md:w-1/2 mt-6 md:mt-0">
              <div className="aspect-w-16 aspect-h-9">
                <img
                  src="https://aigurupublic.s3.us-east-1.amazonaws.com/goaiguru/mexico-live.jpeg"
                  alt="Practical Implementation"
                  className="object-cover rounded-md shadow-md"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* CORPORATE TRAINING PROGRAMS */}
      <section className="bg-salmon py-12 px-4 text-white">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-3xl md:text-4xl font-bold text-center mb-12">
            Corporate Training Programs
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Executive AI Leadership */}
            <div className="bg-white text-black p-6 rounded-md shadow-md hover:shadow-lg transition-shadow">
              <h3 className="text-xl font-semibold mb-4">Executive AI Leadership</h3>
              <ul className="list-disc list-inside text-gray-700">
                <li><strong>AI Strategy Development:</strong> Build comprehensive AI roadmaps aligned with business goals</li>
                <li><strong>Risk Management &amp; Ethics:</strong> Navigate AI implementation challenges with confidence</li>
                <li><strong>ROI &amp; Business Impact Analysis:</strong> Measure and maximize AI investment returns</li>
              </ul>
              <div className="mt-4">
                <Link
                  to="/executive-ai-leadership"
                  className="inline-block bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition"
                >
                  Learn More
                </Link>
              </div>
            </div>

            {/* Technical AI Implementation */}
            <div className="bg-white text-black p-6 rounded-md shadow-md hover:shadow-lg transition-shadow">
              <h3 className="text-xl font-semibold mb-4">Technical AI Implementation</h3>
              <ul className="list-disc list-inside text-gray-700">
                <li><strong>Custom LLM Development:</strong> Fine-tune language models for your domain needs</li>
                <li><strong>Enterprise AI Integration:</strong> Seamlessly integrate AI solutions into existing systems</li>
                <li><strong>AI Infrastructure &amp; Scale:</strong> Design and build scalable AI architectures</li>
              </ul>
              <div className="mt-4">
                <Link
                  to="/technical-ai-implementation"
                  className="inline-block bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition"
                >
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* VIRTUAL TRAINING */}
      <section className="bg-darkgray text-lightgray py-12 px-4">
        <div className="max-w-6xl mx-auto text-center">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">We do virtual training as well</h2>
          <p className="text-lg mb-8">
            Received 4.83/5 Customer Satisfaction Score.
            <br />
            <strong>👈 Not easy to please 750 people virtually in one day!</strong>
          </p>
          <div className="flex flex-col md:flex-row items-center justify-center md:space-x-8">
            {/* IMAGE */}
            <div className="md:w-1/2 mb-6 md:mb-0">
              <div className="aspect-w-16 aspect-h-9">
                <img
                  src="https://aigurupublic.s3.us-east-1.amazonaws.com/goaiguru/growth-650.png"
                  alt="Virtual Training Session"
                  className="object-contain rounded-md shadow-md w-full h-full"
                />
              </div>
            </div>
            {/* TEXT */}
            <div className="md:w-1/2 text-left">
              <h3 className="text-xl font-semibold mb-2">Flexible AI Training</h3>
              <p className="text-base">
                Onsite, Virtual, or Hybrid delivery tailored to your organization’s needs.
                Whether you have 10 or 750 participants, we can accommodate.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* INQUIRY FORM */}
      <section className="bg-white py-12 px-4">
        <div className="max-w-md mx-auto bg-lightgray p-6 rounded-md shadow-md text-center">
          <h2 className="text-2xl font-bold mb-4">Prefer to discuss via email?</h2>
          <p className="text-gray-700 mb-6">
            Drop your email address below and a human will be in touch.
          </p>
          <form className="space-y-4">
            <div className="flex flex-col md:flex-row md:space-x-4">
              <input
                type="text"
                placeholder="First Name"
                className="flex-1 px-4 py-2 border rounded focus:outline-none mb-4 md:mb-0"
              />
              <input
                type="text"
                placeholder="Last Name"
                className="flex-1 px-4 py-2 border rounded focus:outline-none"
              />
            </div>
            <input
              type="email"
              placeholder="Email Address"
              className="w-full px-4 py-2 border rounded focus:outline-none"
            />
            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-2 rounded hover:bg-blue-700 transition"
            >
              Inquire
            </button>
          </form>
        </div>
      </section>

      {/* FOOTER */}
      <SimpleFooter />
    </div>
  );
};

export default CorporateTrainingPage;
