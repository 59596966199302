  import React from "react";

  const GenerativeAIPage = () => {
    return (
      <div className="font-sans my-2">
        {/* First Section */}
        <div className="bg-inherit text-gray-900 px-4 md:px-12 flex-row md:flex md:justify-between my-4">
          <div className="text-start ml-4 my-4">
            <h1 className="text-6xl lg:text-8xl mb-4">
              Unleash <br />
              Creativity <br /> with <br /> Generative AI
            </h1>
            <p className="text-lg lg:text-xl mb-8">
              Transform the way you design, create, and <br /> personalize with
              cutting-edge Generative AI.
            </p>
            <button className="bg-gray-800 text-white py-6 px-12 rounded-lg hover:bg-gray-700">
              Try Demo
            </button>
          </div>
          <div className="m-2">
            <img
              src="https://images.squarespace-cdn.com/content/v1/671f15a34acff55ddc02afbc/9e534fec-3f79-4d3d-b6ef-955ddeedd6b9/genAI.png?format=500w"
              alt="Generative AI Visualization"
              className="h-96 lg:h-full max-w-lg"
            />
          </div>
        </div>

        <section className="bg-gray-700 text-pink-50 px-4 md:px-12 py-4 flex flex-col md:flex-row justify-between">
          {/* Left Side - Image */}
          <div className="flex justify-start pl-4 mb-8 md:mb-0">
            <img
              src="https://images.squarespace-cdn.com/content/v1/5f34ae09db9ae767f8013c9d/6896185e-7797-469c-b3dd-e80a5551221a/zhenyu-luo-kE0JmtbvXxM-unsplash.jpg    " // Replace with your image URL
              alt="Generative AI Illustration"
              className="h-96  md:h-[496px] max-w-lg"
            />
          </div>

          {/* Right Side - Text */}
          <div className="text-start flex h-auto ml-4 items-center max-w-lg md:pr-4">
            <div>
              <h2 className="text-4xl md:text-5xl mb-16 mr-8">What is Generative AI</h2>
              <p className="text-2xl lg:text-3xl leading-10">
                Generative AI leverages machine  learning to generate new content, from 
                images and text to complex designs. This technology enables businesses
                to automate creative processes, build    scalable content pipelines, and
                unlock  new possibilities.
              </p>
            </div>
          </div>
        </section>

        {/* Transforming Industries Section */}
        <section className="bg-pink-500 text-gray-900 px-6 py-16">
          <div className="max-w-7xl mx-auto text-center">
            <h2 className="p-8 text-6xl mb-12">
              Transforming Industries with Generative AI
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className=" bg-white flex flex-col items-center  md:px-10  lg:px-20 pt-4 shadow-lg">
                <h3 className="text-xl font-semibold mb-2">Content Creation</h3>
                <p className="text-gray-700 text-sm mb-2 ">
                  AI-generated marketing copy, blogs, and visuals.
                </p>
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZsJbc-kxqIukAIv1DC35Zaw1pYq4DM3Dus3x9CQI-WOLQobq1eadrrfe87vHaz8sqvmE&usqp=CAU"
                  alt="Content Creation Icon"
                  className="mb-4  w-36 h-36 rounded-full"
                />
              </div>
              <div className="bg-white flex flex-col items-center md:px-10  lg:px-20 pt-4 shadow-lg">
                
                <h3 className="text-xl font-semibold mb-2">Product Design</h3>
                <p className="text-gray-700 text-sm mb-2 ">
                  Prototyping and creative design automation.
                </p>
                <img
                  src="https://scadea.com/wp-content/uploads/2022/10/services-ui-ux.jpg"
                  alt="Product Design Icon"
                  className="mb-4  w-36 h-36 rounded-full"
                />
              </div>
              <div className="bg-white flex flex-col items-center md:px-10  lg:px-20 pt-4 shadow-lg">
                
                <h3 className="text-xl font-semibold mb-2">Personalization</h3>
                <p className="text-gray-700 text-sm mb-2">
                  Custom recommendations and personalized experiences.
                </p>
                <img
                  src="https://img.freepik.com/premium-photo/young-girl-with-dark-skin-dark-skin-nose-ring_786255-20161.jpg"
                  alt="Personalization Icon"
                  className="mb-4  w-36 h-36 rounded-full"
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  };

  export default GenerativeAIPage;
