// src/components/InstructorsPlaceholder.js
import React from "react";
import { Link } from "react-router-dom";

const InstructorsPlaceholder = () => {
  return (
    // Example gradient from your palette
    <div className="bg-gradient-to-r from-lightblue to-salmon py-16 sm:py-24">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 className="text-3xl sm:text-4xl font-bold text-darkgray mb-4">
          Become an Instructor at AI Guru
        </h2>
        <p className="text-lg text-deepblue mb-8 max-w-2xl mx-auto">
          We are looking for talented professionals to share their knowledge with learners from around the world. Join our team and make an impact!
        </p>
        <Link
          to="/instructor-signup"
          className="inline-block bg-gradient-to-r from-lightblue to-salmon text-white font-bold py-2 px-6 rounded-lg hover:opacity-90 transition duration-300"
        >
          Apply to Become an Instructor
        </Link>
      </div>
    </div>
  );
};

export default InstructorsPlaceholder;
