import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import LoadingScreen from './components/LoadingScreen';
import Header from './components/Header';
import SimpleFooter from './components/SimpleFooter';
import AboutUs from './components/AboutUs';
import TermsOfService from './components/TermsOfService';
import PrivacyPolicy from './components/PrivacyPolicy';
import InstructorsPlaceholder from './components/InstructorsPlaceholder';
import ContactUs from './components/ContactUs';
import InstructorSignup from './components/InstructorSignup';
import DripCourseContent from './components/DripCourseContent';
import DripContentViewer from './components/DripContentViewer';
import DripCourseSignup from './components/DripCourseSignup';
import SignupSuccess from './components/SignupSuccess';
import DripCourseWelcome from './components/DripCourseWelcome';
import UpgradePage from './components/UpgradePage';
import FaqPage from './components/FaqPage';
import ReactGA from 'react-ga4';
import GenerativeAIPage from './components/Solution/GenerativeAIPage';
import SolutionPage from './components/Solution/Solution';
import VirtualAgentsPage from './components/Solution/AiAgentSolution';
import CustomAIPage from './components/Solution/CustomAiSolutions';
import CorporateTrainingPage from './pages/CorporateTrainingPage';

// Lazy load components
const Home = lazy(() => import('./components/Home'));
const Courses = lazy(() => import('./components/Courses'));
const CourseDetail = lazy(() => import('./components/CourseDetail'));
const CourseContent = lazy(() => import('./components/CourseContent'));
const Profile = lazy(() => import('./components/Profile'));
const Dashboard = lazy(() => import('./components/Dashboard'));
const LoginForm = lazy(() => import('./components/LoginForm'));
const CourseLandingPage = lazy(() => import('./components/CourseLandingPage'));
const CongratulationsPage = lazy(() => import('./components/CongratulationsPage'));

const TRACKING_ID = 'G-MH1J1103E1';
ReactGA.initialize(TRACKING_ID);

const ProtectedRoute = ({ component: Component, ...args }) => {
  const ProtectedComponent = withAuthenticationRequired(Component, {
    onRedirecting: () => <LoadingScreen />,
  });
  return (
    <Suspense fallback={<LoadingScreen />}>
      <ProtectedComponent {...args} />
    </Suspense>
  );
};

const ConditionalHeader = () => {
  const location = useLocation();
  const pathStartsWith = (prefix) => location.pathname.startsWith(prefix);
  const hideHeader = pathStartsWith('/courses/') && !pathStartsWith('/courses/content');
  return hideHeader ? null : <Header />;
};

const ConditionalFooter = () => {
  const location = useLocation();
  return location.pathname === '/' ? null : <SimpleFooter />;
};

function AnalyticsTracker() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);

  return null;
}

function App() {
  const onRedirectCallback = (appState) => {
    const returnTo = appState?.returnTo || window.location.pathname;
    window.location.replace(returnTo);
  };

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: 'openid profile email offline_access start:notebooks',
      }}
      onRedirectCallback={onRedirectCallback}
    >
      <Router>
        <AnalyticsTracker />
        <div className="flex flex-col min-h-screen bg-lightgray">
          <ConditionalHeader />
          <main className="flex-grow">
            <Suspense fallback={<LoadingScreen />}>
              <Routes>
                {/* Public Routes */}
                <Route path="/" element={<Home />} />
                <Route path="/faq" element={<FaqPage />} />
                <Route path="/courses" element={<Courses />} />
                <Route path="/courses/:id" element={<CourseLandingPage />} />
                <Route path="/congratulations" element={<CongratulationsPage />} />
                <Route path="/aboutus" element={<AboutUs />} />
                <Route path="/termsofservice" element={<TermsOfService />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/instructor" element={<InstructorsPlaceholder />} />
                <Route path="/contactUs" element={<ContactUs />} />
                <Route path="/instructor-signup" element={<InstructorSignup />} />
                <Route path="/corporate-training" element={<CorporateTrainingPage />} />
                <Route path="/login" element={<LoginForm />} />

                {/* Protected Routes */}
                <Route path="/courses/:id/enroll" element={<ProtectedRoute component={CourseDetail} />} />
                {/* Add the upgrade route */}
                <Route
                  path="/courses/:courseId/upgrade"
                  element={<ProtectedRoute component={UpgradePage} />}
                />

                {/* Drip Course Routes */}
                <Route path="/courses/:courseId/subscribe" element={<DripCourseSignup />} />
                <Route path="/courses/:courseId/welcome" element={<DripCourseWelcome />} />
                <Route path="/courses/:courseId/signup-success" element={<SignupSuccess />} />

                <Route
                  path="/courses/:courseId/drip-content"
                  element={<ProtectedRoute component={DripContentViewer} />}
                />
                <Route
                  path="/courses/:courseId/drip-content/:sectionIndex/:lectureId"
                  element={<ProtectedRoute component={DripContentViewer} />}
                />
                {/* Standalone signup page */}
                <Route path="/subscribe/:courseId" element={<DripCourseSignup />} />

                {/* Course Content Routes */}
                <Route
                  path="/courses/:courseId/content/:sectionIndex/:lectureId"
                  element={<ProtectedRoute component={CourseContent} />}
                />
                <Route
                  path="/courses/:courseId/content/:sectionIndex"
                  element={<ProtectedRoute component={CourseContent} />}
                />
                <Route
                  path="/courses/:courseId/content"
                  element={<ProtectedRoute component={CourseContent} />}
                />

                {/* User Routes */}
                <Route path="/profile" element={<ProtectedRoute component={Profile} />} />
                <Route path="/dashboard" element={<ProtectedRoute component={Dashboard} />} />
                <Route path="/solution" element={<SolutionPage />}>
                  <Route path="gen-ai-solutions" element={<GenerativeAIPage />} />
                  <Route path="ai-agents-solutions" element={<VirtualAgentsPage />} />
                  <Route path="custom-ai-solutions" element={<CustomAIPage />} />
                </Route>

                {/* Fallback Route */}
                <Route path="*" element={<Home />} />

              </Routes>
            </Suspense>
          </main>
          <ConditionalFooter />
        </div>
      </Router>
    </Auth0Provider>
  );
}

export default App;
